/* eslint-disable prefer-template */
import { Track } from '../libs/player';

export const tracks: Track[] = [
  {
    title: 'Two Dragons',
    url: process.env.PUBLIC_URL + '/books/AUDIOBOOK_TWO_DRAGONS.mp3',
  },
  {
    title: 'Beast of Beswick',
    url: process.env.PUBLIC_URL + '/books/Beast_of_Beswick_NinaNato.mp3',
  },
  {
    title: 'Cosy Detective',
    url: process.env.PUBLIC_URL + '/books/Cosy_Detective.mp3',
  },
  {
    title: 'Next Best Thing',
    url: process.env.PUBLIC_URL + '/books/Next_Best_Thing.mp3',
  },
  {
    title: 'Poker Chips & Poison',
    url: process.env.PUBLIC_URL + '/books/PokerChips&Poison.mp3',
  },
];
