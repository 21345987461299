import React, { ReactNode } from 'react';
import { ImgSides } from './WorksCardImg';
import useBreakPoint from '../hooks/useBreakPoint';
import WorksCardTitle from './WorksCardTitle';

type Props = {
  title: string;
  imgSide: keyof typeof ImgSides;
  children: ReactNode;
};

export default function WorksCardDescription(props: Props) {
  const { imgSide, title, children } = props;
  const downSm = useBreakPoint('down', 'sm');

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: '0 1 100%',
        height: '100%',
        justifyContent: 'center',
        padding:
          ImgSides[imgSide] === ImgSides.left
            ? '0 4vw 0 7.5vw'
            : '0 7.5vw 0 4vw',
      }}
    >
      {!downSm && <WorksCardTitle title={title} />}
      {children}
    </div>
  );
}
