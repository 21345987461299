/* eslint-disable global-require */
// import { makeStyles } from '@material-ui/core/styles';
// import { useTheme, Theme } from '@material-ui/core';
// import { useTheme } from '@material-ui/core';
import WorksCard from '../components/WorksCard';
// import useWindowSize from '../hooks/useWindowResize';
import ParallaxBackground from '../components/ParallaxBackground';
// import useBreakPoint from '../hooks/useBreakPoint';
import Heading from './frontend-works/Heading';
import StarsBackground from '../components/StarsBackground';
import BooksPlayer from '../components/BooksPlayer';
import GamesPlayer from '../components/GamesPlayer';
import AdsPlayer from '../components/AdsPlayer';

// type StyleProps = {
//   downSm: boolean;
//   theme: Theme;
// };

// const useStyles = makeStyles({
//   technologies: ({ theme, downSm }: StyleProps) => ({
//     color: theme.palette.primary.contrastText,
//     textAlign: downSm ? 'center' : 'initial',
//   }),
// });

export default function FrontendWorks() {
  // const theme = useTheme();
  // const { width: windowWidth } = useWindowSize();
  // const downSm = useBreakPoint('down', 'sm');
  // const classes = useStyles({ downSm, theme });

  // function responsiveText(long: string, short: string): string {
  //   return windowWidth >= theme.breakpoints.values.sm ? long : short;
  // }

  return (
    <section
      id="works"
      style={{
        display: 'flex',
        paddingTop: '7.5vw',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <ParallaxBackground />
      <Heading />
      <WorksCard
        descriptionProps={{
          title: 'Audiobooks',
        }}
        marginBottom="7.5vw"
        imgSide="right"
        backgroundColor="white"
        imgProps={{
          src: require('../assets/images/NanoNagle2.webp').default,
          srcFallback: require('../assets/images/NanoNagle2.jpeg').default,
          placeholder: require('../assets/images/NanoNagle2_lowres.webp')
            .default,
          placeholderFallback:
            require('../assets/images/NanoNagle2_lowres.jpeg').default,
        }}
      >
        <BooksPlayer />
      </WorksCard>
      <WorksCard
        descriptionProps={{
          title: 'Games',
        }}
        marginTop="7.5vw"
        marginBottom="7.5vw"
        imgSide="left"
        background="linear-gradient(rgb(19, 24, 42) 0%, rgb(38, 56, 103) 100%)"
        imgProps={{
          src: require('../assets/images/NanoNagle4.webp').default,
          srcFallback: require('../assets/images/NanoNagle4.jpeg').default,
          placeholder: require('../assets/images/NanoNagle4_lowres.webp')
            .default,
          placeholderFallback:
            require('../assets/images/NanoNagle4_lowres.jpeg').default,
        }}
        zIndex={1}
      >
        <StarsBackground id="stars-calculator" />
        <GamesPlayer />
      </WorksCard>
      <WorksCard
        descriptionProps={{
          title: 'Advertisements',
        }}
        marginTop="7.5vw"
        marginBottom="7.5vw"
        imgSide="right"
        backgroundColor="black"
        imgProps={{
          src: require('../assets/images/NanoNagle3.webp').default,
          srcFallback: require('../assets/images/NanoNagle3.jpeg').default,
          placeholder: require('../assets/images/NanoNagle3_lowres.webp')
            .default,
          placeholderFallback:
            require('../assets/images/NanoNagle3_lowres.jpeg').default,
        }}
      >
        <StarsBackground id="stars-carbon" flowDown />
        <AdsPlayer />
      </WorksCard>
    </section>
  );
}
