export default function Description() {
  return (
    <>
      <h3>
        What has always made me happy is acting and from a very young age I have
        found solace as well as excitement in performance. So even though I
        studied Economics and Politics at Uni - I never stopped acting and
        eventually went to Drama School. My first jobs in the theatre were with
        children and part of me has never left that arena; it’s wonderful how
        children’s imaginations can break such boundaries in storytelling.
      </h3>
      <h3>
        Years in the theatre saw me working in the classics, community
        conceptual art, fringe theatre, musicals and improvisational pieces,
        European, Australian and English mostly. Lots of touring, West End and
        out in the fresh air. Lots of storytelling! Lots of audiences!
      </h3>
      <h3>
        So when I went to a workshop on voiceovers a few years ago, a bright
        light pinged and I started narrating stories and voicing the occasional
        radio play/drama.
      </h3>
      <h3>
        Then I set up my little studio and learnt all about sound levels and the
        wonderful world of Audiobooks, Games, Audioguides and the myriad ways
        you can perform through the sound waves!
      </h3>
      <h3>Life is still full of stories, performances and learning!</h3>
    </>
  );
}
