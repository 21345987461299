import { SocialIcon } from 'react-social-icons';
import styled from 'styled-components';
import { Grid, useTheme } from '@material-ui/core';
import { transition } from '../../styles/StyledComponentsStyles';

const HoverableSocialIcon = styled(SocialIcon)`
  ${transition}
  &:hover {
    transform: scale(1.15);
  }
`;

export default function SocialIcons() {
  const theme = useTheme();
  return (
    <Grid
      item
      container
      direction="column"
      xs={12}
      style={{ marginTop: '2em' }}
    >
      <div style={{ display: 'flex', flex: 1 }}>
        <div
          style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <HoverableSocialIcon
            bgColor={theme.palette.secondary.light}
            url="https://www.facebook.com/nano.nagle.12"
            target="_blank"
            rel="noopener noreferrer"
          />
          <HoverableSocialIcon
            url="https://www.instagram.com/nnagle51/"
            target="_blank"
            rel="noopener noreferrer"
          />
          <HoverableSocialIcon
            url="https://twitter.com/nnagle51"
            target="_blank"
            rel="noopener noreferrer"
          />
        </div>
      </div>
    </Grid>
  );
}
