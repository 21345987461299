/* eslint-disable prefer-template */
import { Track } from '../libs/player';

export const tracks: Track[] = [
  {
    title: 'Audio Guide - Two Artists',
    url: process.env.PUBLIC_URL + '/ads/audioguide_Two_Artists.mp3',
  },
  {
    title: 'Audio Guide - La Oratava',
    url: process.env.PUBLIC_URL + '/ads/audioguideLaOratava_Nano_Nagle.mp3',
  },
  {
    title: 'Goalkeepers',
    url: process.env.PUBLIC_URL + '/ads/Goalkeepers.mp3',
  },
  {
    title: "La Musee d'Orsay",
    url: process.env.PUBLIC_URL + "/ads/La_Musee_d'Orsay.mp3",
  },
  {
    title: 'Louis Vuitton',
    url: process.env.PUBLIC_URL + '/ads/LouisVuitton.mp3',
  },
  {
    title: 'Medical Animation',
    url: process.env.PUBLIC_URL + '/ads/Medical_Animation.mp3',
  },
];
