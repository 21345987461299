/* eslint-disable prefer-template */
import { Track } from '../libs/player';

export const tracks: Track[] = [
  {
    title: 'Game Narration',
    url: process.env.PUBLIC_URL + '/games/Game_Narration.mp3',
  },
  {
    title: 'Lyston',
    url: process.env.PUBLIC_URL + '/games/Lyston.mp3',
  },
  {
    title: 'Endeavour',
    url: process.env.PUBLIC_URL + '/games/Endeavour.mp3',
  },
  {
    title: 'Planetfall',
    url: process.env.PUBLIC_URL + '/games/Planetfall.mp3',
  },
  {
    title: 'Mid Atlantic',
    url: process.env.PUBLIC_URL + '/games/Mid_Atlantic.mp3',
  },
];
