import Grid from '@material-ui/core/Grid';
import { Link } from '../components/Link';
import Section from './contact/Section';
import SocialIcons from './contact/SocialIcons';

export default function Contact() {
  return (
    <Section id="contact">
      <h1>Let’s Create Something Together!</h1>
      <Grid container>
        <Grid item container direction="column" xs={12}>
          <h3 style={{ paddingTop: 0 }}>
            Feel free to reach out to me if you’re looking for a voiceover
            artist for your next project.
          </h3>
          <Link href="mailto:nnagle51@gmail.com">nnagle51@gmail.com</Link>
        </Grid>
        <SocialIcons />
      </Grid>
    </Section>
  );
}
