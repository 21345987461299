import { motion } from 'framer-motion';
import useBreakPoint from '../hooks/useBreakPoint';
import * as AnimationVariants from '../styles/AnimationVariants';
import NanoNagle from '../assets/images/NanoNagle.jpeg';

export default function Logo() {
  const upSm = useBreakPoint('up', 'sm');
  return (
    <motion.div
      variants={AnimationVariants.deScale}
      initial="zoomed"
      animate="normal"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        top: '8px',
      }}
    >
      <motion.img
        src={NanoNagle}
        alt="Nano Nagle"
        variants={AnimationVariants.fadeIn}
        initial="fading"
        animate="faded"
        style={{
          width: upSm ? '23.75rem' : '15rem',
          // height: upSm ? '23.75rem' : '20rem',
        }}
      />
    </motion.div>
  );
}
